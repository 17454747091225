import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "./../shared/components/layout";
import PageHeader from "../shared/components/page-header";
import DynamicImage from "../shared/components/dynamic-image";
import { useInView } from "react-intersection-observer";
import './index.scss'

const About = (props) => {
  const { pageContext } = props
  const { t } = useTranslation();

  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '-100px 0px',
  });

  const metas = {
    title: t("seo.about.title"),
    description: t("seo.about.description")
  }

  const contentItems = t("about.content.items", { returnObjects: true })

  return (
    <Layout
      metas={metas}
      pageContext={pageContext}
    >
      <PageHeader
        title={t("about.title")}
        description={t("about.description")}
        type='about'
      />
      <div className="wrapper-fluid">
        <div ref={ref} className={`about-page-content grid${inView ? ' in-view' : ''}`}>
          <div className="image-left-wrapper">
            <DynamicImage imageKey={t("about.imageLeft")} />
          </div>
          <div className="content-image-bottom-wrapper">
            <p className="content-intro">{t("about.content.intro")}</p>
            <ul className="content-list">
              {
                contentItems.map((content, index) => {
                  return (<li key={`about-content-item-${index}`}>{content}</li>)
                })
              }
            </ul>
            <div className="images-bottom-wrapper grid">
              <DynamicImage imageKey={t("about.imageBottomLeft")} />
              <DynamicImage imageKey={t("about.imageBottomRight")} />
            </div>
          </div>
        </div>
      </div>

    </Layout>
  );
};

export default About;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
